<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 95.5 95.2"
    enable-background="new 0 0 95.5 95.2"
    xml:space="preserve"
  >
    <gradient-helper />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="0.7"
      stroke-miterlimit="10"
      d="M35.8,94.7V69.2c0-3.1-1.1-5.9-3.4-8.1c-2.3-2.3-5-3.4-8.1-3.4H0.3v37L35.8,94.7z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="0.7"
      stroke-miterlimit="10"
      d="M95.1,94.9V0.5c0.2-0.2-63.3,0-63.3,0v21.7c0,4.5-0.9,8.9-2.8,13.3c-0.5,1.1,0.2,2.4,1.4,2.5c4,0.4,12,1.5,17.4,4.9c9.4,5.9,11.8,16,11.8,26.3v25.6C59.7,94.7,95.3,94.9,95.1,94.9z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="0.7"
      stroke-miterlimit="10"
      d="M0.6,32.7c2-0.8,3.7-2.1,5-3.9c1.3-1.9,2-4.1,2-6.6V0.5h-7V32.7z"
    />
  </svg>
</template>

<script>
import svg from '@/mixins/svg'
import GradientHelper from './GradientHelper.vue'
export default {
  components: { GradientHelper },
  mixins: [svg]
}
</script>
