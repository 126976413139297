<template>
  <v-card
    v-if="company"
    outlined
    width="100%"
    height="10%"
    class="flex-wrap d-flex flex-column"
  >
    <template>
      <v-card-title class="mt-4">
        <h4 class="px-4 title keep-word-break">
          So erhalten Sie direkte Unterstützung
        </h4>
      </v-card-title>
      <div class="flex-wrap d-flex">
        <div class="px-4 pb-4 d-flex align-center flex-grow-1">
          <v-avatar
            v-for="(image, index) in images"
            :key="index"
            size="48"
            :style="{'z-index':images.length - index, 'margin-left':`${ index === 0 ? 0 : -25 }px`}"
          >
            <img
              width="50px"
              :src="image"
              :alt="index"
            >
          </v-avatar>
          <span class="ml-2 align-center text-body-1">
            Unser Customer Success Team ist für Sie da:
          </span>
        </div>
        <div
          v-if="isExpert"
          class="px-4 pb-4 flex-grow-1 d-flex flex-shrink-1"
        >
          <v-icon class="mr-4">
            mdi-phone
          </v-icon>
          <div class="justify-center d-flex flex-column">
            <a :href="`tel:${phone}`">
              {{ phone }}
            </a>
            Telefon
          </div>
        </div>
        <div class="px-4 pb-4 flex-grow-1 d-flex flex-shrink-1">
          <v-icon class="mr-4">
            mdi-email
          </v-icon>
          <div class="justify-center d-flex flex-column">
            <a :href="`mailto:${email}`">
              {{ email }}
            </a>
            E-Mail
          </div>
        </div>
      </div>
    </template>
  </v-card>
</template>

<script>
import { isAuthenticatedAndBasicUser, isAuthenticatedAndExpertUser } from '@/lib/productTypes'
import featureNames from '@/lib/featureNames'

const csImages = [
  'img/customerSuccess/yannick.jpg',
  'img/customerSuccess/svenja.jpg',
  'img/customerSuccess/fabienne.png',
  'img/customerSuccess/andre.png',
  'img/customerSuccess/marcel.webp'
]

export default {
  props: {
    company: {
      type: Object,
      default: null
    },
    feedbackOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    images () {
      return csImages
    },
    defaultContact () {
      return this.$features.feature(featureNames.SUPPORT_CONTACT).config
    },
    phone () {
      const { clientAdvisor, country } = this.company

      if (clientAdvisor) {
        return clientAdvisor.phone[country]
      }

      return this.defaultContact.phone[country]
    },

    email () {
      if (isAuthenticatedAndBasicUser(this.$auth)) {
        return this.defaultContact.support.email
      }

      if (this.company?.clientAdvisor) {
        return this.company.clientAdvisor.email
      }

      return this.defaultContact.email
    },

    isExpert () {
      return isAuthenticatedAndExpertUser(this.$auth)
    }
  }
}
</script>

<style scoped>

.title {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.keep-word-break {
  word-break: keep-all;
}
</style>
