<template>
  <div>
    <BaseHeader
      title="Wissen, Hilfe und Fortbildung"
      :style-variant="4"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>{{ pageLabels[platform].intro }}</p>
      </template>
    </BaseHeader>

    <Feature :feature-slug="featureNames.ASKAI_ASSISTENT_PREVIEW">
      <v-row>
        <v-col cols="12">
          <AskAi />
        </v-col>
      </v-row>
    </Feature>

    <v-row>
      <Feature
        v-for="(featureSlug, topic) in topics"
        v-slot="{feature}"
        :key="topic"
        :feature-slug="featureSlug"
      >
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <HelpCard
            :topic="topic"
            :config="feature.config"
          />
        </v-col>
      </Feature>
      <support-form-card v-if="!hasDedicatedSupport && !isLight" />
    </v-row>

    <support
      v-if="hasDedicatedSupport"
      class="mt-4"
      :company="company"
    />
  </div>
</template>

<script>
import HelpCard from '@/modules/helpCenter/HelpCard'
import Support from '@/modules/helpCenter/Support'
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'
import brandingMixin from '@/mixins/branding'
import BaseHeader from '@/components/BaseHeader.vue'
import SupportFormCard from './SupportFormCard.vue'
import HeaderDesign from './HeaderDesign.vue'
import AskAi from './AskAi.vue'
import { isAuthenticatedAndLightUser } from '@/lib/productTypes'

const pageLabels = {
  bottimmo: {
    intro: `Holen Sie das Maximum aus Ihrem BOTTIMMO-Paket heraus.
      Wir unterstützen Sie mit allen Möglichkeiten - mit praxisnahem Wissen, starken Fortbildungen und konkreter Hilfe.`
  },
  remax: {
    intro: `Holen Sie das Maximum aus Ihrem Leadturbo heraus.
      Wir unterstützen Sie mit allen Möglichkeiten - mit praxisnahem Wissen, starken Fortbildungen und konkreter Hilfe.`
  }
}

export default {
  components: {
    HelpCard,
    Support,
    Feature,
    BaseHeader,
    SupportFormCard,
    HeaderDesign,
    AskAi
  },
  mixins: [brandingMixin],
  props: {
    company: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      pageLabels,
      featureNames
    }
  },
  computed: {
    topics () {
      return { CALENDAR: featureNames.EVENT_CALENDAR, BOTTITHEK: featureNames.BOTTITHEK, HELPCENTER: featureNames.HELPCENTER }
    },
    hasDedicatedSupport () {
      return this.$features.feature(featureNames.APP_BRANDING).config?.hasDedicatedSupport
    },
    isLight () {
      return isAuthenticatedAndLightUser(this.$auth)
    }
  }
}
</script>
