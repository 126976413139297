<template>
  <v-card outlined>
    <v-card-title class="d-flex align-items-center">
      <v-chip
        class="mr-2"
        label
        color="info"
      >
        Preview
      </v-chip>
      <span>Mein KI Assistent</span>
    </v-card-title>
    <v-card-text>
      <p>
        Diese Preview-Version wurde mit mehr Inhalten trainiert, wie z.B. HelpCenter Artikel oder der Website.
        Diese steht den Kunden noch nicht zur Verfügung.
      </p>
    </v-card-text>
    <AskAi id="o9ACC9T3HEnBg6zX989UStdaNlzVNW" />
  </v-card>
</template>

<script>
import AskAi from '@/components/AskAi.vue'

export default {
  components: {
    AskAi
  }
}
</script>
