
<template>
  <v-card
    outlined
    height="100%"
    class="d-flex flex-column"
  >
    <v-img
      max-height="150"
      :src="logo"
    />

    <v-card-title class="justify-center d-flex my-md-4">
      <h4>
        {{ title }}
      </h4>
    </v-card-title>

    <v-card-text class="px-6 content text-body-1">
      {{ text }}
    </v-card-text>

    <v-row class="mt-2 mr-4 mt-md-6 footer">
      <v-btn
        class="primary"
      >
        {{ linkText }}
        <v-icon
          v-if="isExternal"
          class="ml-2"
          size="14"
        >
          mdi-open-in-new
        </v-icon>
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    },
    isExternal: {
      type: Boolean,
      default: false
    }
  }

}
</script>

<style scoped>
.content {
  flex: 1 1 auto;
}

.footer {
  flex: 0 1 80px;
  justify-content: flex-end;
}
</style>
