<template>
  <router-link
    v-if="route"
    :to="{ name: route }"
    class="text-decoration-none"
  >
    <HelpCardContent
      v-bind="topicContent"
    />
  </router-link>
  <a
    v-else
    :href="link"
    :target="topicContent.target"
    class="text-decoration-none"
    @click="topicContent.clickEvent && handleClickEvent(topicContent.clickEvent)"
  >
    <HelpCardContent
      v-bind="topicContent"
      is-external
    />
  </a>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import { buildThinkificUrl } from '@/lib/thinkific'
import HelpCardContent from './HelpCardContent.vue'

const TOPIC = {
  bottimmo: {
    CALENDAR: {
      title: 'Termine und Fortbildung',
      text: 'Checken Sie unseren Stundenplan mit allen Fortbildungs- und Informationsangeboten. Hier finden Sie starke Angebote, die woanders richtig Geld kosten. Als BOTTIMMO-Kunde ist alles inklusive. Melden Sie sich gleich an!',
      route: 'calendar',
      linkText: 'Kalender öffnen',
      logo: 'img/helpcenter-kalender.png'
    },
    BOTTITHEK: {
      title: 'BottiThek',
      text: 'Willkommen in der Welt des Wissens. Wissen, um Ihnen mehr Power im Akquise-Marketing zu geben. Schulungsvideos, Textvorlagen, Übungen – diese Schatzkammer wird ständig aktualisiert.',
      clickEvent: 'bottithek',
      linkText: 'BottiThek öffnen',
      target: '_blank',
      logo: 'img/helpcenter-bottithek.png'
    },
    HELPCENTER: {
      title: 'Hilfe-Center',
      text: 'Bei BOTTIMMO gibt es so Einiges zu entdecken. Damit Sie sich nicht verlaufen, sondern alle Angebote optimal einsetzen können, gibt es den Hilfebereich. Wir nehmen Sie an die Hand und sorgen für die richtigen Antworten auf Ihre Fragen.',
      linkText: 'Hilfe öffnen',
      target: '_blank',
      logo: 'img/helpcenter-hilfe.png'
    }
  },
  remax: {
    CALENDAR: {
      title: 'Termine und Fortbildung',
      text: 'Checken Sie unseren Stundenplan mit allen Fortbildungs- und Informationsangeboten. Hier finden Sie starke Angebote, die woanders richtig Geld kosten. Als BOTTIMMO-Kunde ist alles inklusive. Melden Sie sich gleich an!',
      route: 'calendar',
      linkText: 'Kalender öffnen',
      logo: 'img/helpcenter-kalender.png'
    },
    BOTTITHEK: {
      title: 'BottiThek',
      text: 'Willkommen in der Welt des Wissens. Wissen, um Ihnen mehr Power im Akquise-Marketing zu geben. Schulungsvideos, Textvorlagen, Übungen – diese Schatzkammer wird ständig aktualisiert.',
      clickEvent: 'bottithek',
      linkText: 'BottiThek öffnen',
      target: '_blank',
      logo: 'img/helpcenter-bottithek.png'
    },
    HELPCENTER: {
      title: 'Hilfe-Center',
      text: 'Im Leadturbo gibt es so Einiges zu entdecken. Damit Sie sich nicht verlaufen, sondern alle Angebote optimal einsetzen können, gibt es den Hilfebereich. Wir nehmen Sie an die Hand und sorgen für die richtigen Antworten auf Ihre Fragen.',
      linkText: 'Hilfe öffnen',
      target: '_blank',
      logo: 'img/helpcenter-hilfe.png'
    }
  }
}

export default {
  components: { HelpCardContent },
  mixins: [brandingMixin],
  props: {
    topic: {
      type: String,
      default: 'null'
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    topicContent () {
      return TOPIC[this.platform][this.topic]
    },
    route () {
      return this.config.route || TOPIC[this.platform][this.topic].route
    },
    link () {
      return this.config.url || TOPIC[this.platform][this.topic].link
    }
  },
  methods: {
    handleClickEvent (event) {
      if (event === 'bottithek') {
        this.openThinkific()
      }
    },
    async openThinkific () {
      const url = await buildThinkificUrl(this.config.landingpage)
      window.open(url)
    }
  }
}
</script>
